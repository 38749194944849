@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}


.passwordContainer {
  width: 100%;
  margin-bottom: 24px;
  position: relative;

  & .showHidePass {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 38px;
    cursor: pointer;

    &>svg {
      width: 20px;
      height: 20px;
    }
  }
}